import React from 'react';
import { createPopper } from '@popperjs/core';
import { useRecoilState } from 'recoil';
import userState from 'app-recoil/atoms/user';
import { CookiesStorage } from 'utils/cookie-storage';
import { CookieKey } from 'constant';

const UserDropdown = () => {
  const [user, setUser] = useRecoilState(userState);
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-end',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const onLogout = e => {
    e.preventDefault();
    CookiesStorage.clearCookieData(CookieKey.AccessToken);
    CookiesStorage.clearCookieData(CookieKey.UserInfo);
    window.location.reload();
  };

  return (
    <>
      <a
        className="text-white block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={e => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require('assets/img/team-1-800x800.jpg')}
            />
          </span>
          <div className="flex flex-col justify-center">
            <span className="md:text-white text-md font-medium ml-3">{user.fullName || ''}</span>
            <span className="md:text-white text-xs ml-3">{user.email || ''}</span>
          </div>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }
      >
        <a
          href="#pablo"
          className={'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'}
          onClick={onLogout}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
