import { useRecoilState } from 'recoil';
import otpInputState, { DefaultOTPInputState } from 'app-recoil/atoms/otp-input';
import { useQuery } from 'react-query';
import { sendOTP } from 'services/auth';

let resolve;
const useOTPInput = () => {
  const [OTPInputState, setOTPInputState] = useRecoilState(otpInputState);
  const { refetch: sendSMSOTP } = useQuery(['SendSMSOTP'], () => sendOTP(), {
    enabled: false,
    onSuccess: async () => {
      // document.getElementById('otp-modal').checked = true;
      setOTPInputState({
        open: true,
        code: '',
      });
    },
  });

  const triggerOTP = () => {
    return new Promise(res => {
      resolve = res;
      sendSMSOTP();
    });
  };

  const onSubmit = code => {
    resolve?.({ code });
    setOTPInputState(DefaultOTPInputState);
  };

  const onCancel = () => {
    setOTPInputState(DefaultOTPInputState);
    resolve?.({ isCancel: true });
  };

  return { triggerOTP, onCancel, onSubmit, isOpen: OTPInputState?.open };
};

export default useOTPInput;
