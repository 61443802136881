import useOTPInput from 'hooks/use-otp-input';
import { useRef, useState } from 'react';
import AuthCode from 'react-auth-code-input';

export default function OTPInput() {
  const [code, setCode] = useState('');
  const codeInputRef = useRef();
  const { onCancel, onSubmit, isOpen } = useOTPInput();

  const handleSubmit = () => {
    if (code.length < 6) return;
    onSubmit(code);
    codeInputRef.current.clear();
  };
  const handleCancel = () => {
    onCancel();
    codeInputRef.current.clear();
  };
  return (
    <>
      <input type="checkbox" id="otp-modal" className="modal-toggle" checked={isOpen} />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-white">Xác nhận OTP</h3>
          <p className="text-gray-300 py-4">Điền mã OTP vừa gửi đến số điện thoại ...</p>
          <AuthCode
            ref={codeInputRef}
            onChange={res => setCode(res)}
            autoFocus
            inputClassName="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-3xl text-center shadow focus:outline-none focus:ring focus:border-0 ease-linear transition-all duration-150"
            containerClassName="px-6 grid grid-cols-6 gap-4"
          />
          <div className="modal-action">
            <button className="btn btn-default" onClick={handleCancel}>
              Huỷ bỏ
            </button>
            <button className="btn btn-info" onClick={handleSubmit}>
              Đồng ý
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
