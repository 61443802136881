import React, { Fragment, lazy, Suspense } from 'react';
import { Routes as Routers, Route, Navigate } from 'react-router-dom';

import Admin from 'layouts/Admin';
import Auth from 'layouts/Auth';
import { CookiesStorage } from 'utils/cookie-storage';
import { PathName } from 'constant';

// Modules
const Login = lazy(() => import('views/auth/Login'));
const Register = lazy(() => import('views/auth/Register'));
const UserManagement = lazy(() => import('views/admin/UserManagement'));
const DepositManagement = lazy(() => import('views/admin/DepositManagement'));
const WithdrawManagement = lazy(() => import('views/admin/WithdrawManagement'));
const BankAccountManagement = lazy(() => import('views/admin/BankAccountManagement'));
const BankAccountDetail = lazy(() => import('views/admin/BankAccountManagement/BankAccountDetail'));
const DetailTokenOfUser = lazy(() => import('views/admin/BankAccountManagement/DetailTokenOfUser'));
const HistoryInterestDetail = lazy(() =>
  import('views/admin/BankAccountManagement/components/HistoryInterestDetail')
);
const CompanyManagement = lazy(() => import('views/admin/CompanyManagement'));
const CompanyDetail = lazy(() => import('views/admin/CompanyManagement/CompanyDetail'));
const UserDetail = lazy(() => import('views/admin/UserManagement/UserDetail'));
const TokenManagement = lazy(() => import('views/admin/TokenManagement'));
const TokenManagementDetail = lazy(() =>
  import('views/admin/TokenManagement/components/TokenManagementDetail')
);
const PurchaseSaleManagement = lazy(() => import('views/admin/PurchaseSaleManagement'));
const DS1EarnManagement = lazy(() => import('views/admin/DS1EarnManagement'));
const ReferralCodesManagement = lazy(() => import('views/admin/ReferralCodesManagement'));

const TokenDetail = lazy(() => import('views/admin/TokenDetail'));

const getAdminChildPath = path => path?.replace('/admin/', '');
const getAuthChildPath = path => path?.replace('/auth/', '');

const routesConfig = [
  {
    path: '/admin/*',
    layout: Admin,
    children: [
      {
        path: getAdminChildPath(PathName.UserManagement),
        element: UserManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.UserDetail),
        element: UserDetail,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.BankAccountDetail),
        element: BankAccountDetail,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.TokenManagementDetail),
        element: TokenManagementDetail,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.DetailTokenOfUser),
        element: DetailTokenOfUser,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.HistoryInterestDetail),
        element: HistoryInterestDetail,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.BankAccountManagement),
        element: BankAccountManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.DepositManagement),
        element: DepositManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.WithdrawManagement),
        element: WithdrawManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.CompanyManagement),
        element: CompanyManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.CompanyDetail),
        element: CompanyDetail,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.TokenManagement),
        element: TokenManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.DS1EarnManagement),
        element: DS1EarnManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.PurchaseSaleManagement),
        element: PurchaseSaleManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.ReferralCodesManagement),
        element: ReferralCodesManagement,
        requireRoles: [],
      },
      {
        path: getAdminChildPath(PathName.TokenDetail),
        element: TokenDetail,
        requireRoles: [],
      },
      {
        path: '*',
        element: () => <Navigate to={getAdminChildPath(PathName.UserManagement)} replace />,
      },
    ],
  },
  {
    path: '/auth/*',
    layout: Auth,
    children: [
      {
        path: getAuthChildPath(PathName.AuthLogin),
        element: Login,
        requireRoles: [],
      },
      {
        path: getAuthChildPath(PathName.AuthRegister),
        element: Register,
        requireRoles: [],
      },
      {
        path: '*',
        element: () => <Navigate to={getAuthChildPath(PathName.AuthLogin)} replace />,
      },
    ],
  },
];

const getNestedRoute = routes => {
  return routes?.map((item, index) => {
    const Component = item.element;

    return <Route key={`routes-child-${index}`} path={item.path} element={<Component />} />;
  });
};

const renderRoutes = allRoutes => {
  const isAuthenticated = CookiesStorage.isAuthenticated();
  const routes = isAuthenticated ? [allRoutes[0]] : [allRoutes[1]];

  return (
    <>
      {routes ? (
        <Suspense fallback={<div />}>
          <Routers>
            {routes.map((route, idx) => {
              // const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.element;
              // const requireRoles = route.requireRoles || [];

              return (
                <Route
                  key={`routes-${idx}`}
                  path={route.path}
                  element={Component ? <Component /> : <Layout />}
                >
                  {getNestedRoute(route?.children)}
                </Route>
              );
            })}
            <Route
              path="*"
              element={
                <Navigate
                  to={isAuthenticated ? PathName.UserManagement : PathName.AuthLogin}
                  replace
                />
              }
            />
          </Routers>
        </Suspense>
      ) : null}
    </>
  );
};

const Routes = () => {
  return renderRoutes(routesConfig);
};

export default Routes;
