/*eslint-disable*/
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserDropdown from 'components/Dropdowns/UserDropdown.js';
import { PathName } from 'constant';

export default function Sidebar() {
  const location = useLocation();
  const { pathname } = location || {};
  const [collapseShow, setCollapseShow] = React.useState('hidden');

  const menuItems = [
    {
      key: 'menu-1',
      label: 'Quản lý người dùng',
      path: PathName.UserManagement,
      active: [PathName.UserManagement].includes(pathname),
    },
    {
      key: 'menu-2',
      label: 'Quản lý tài khoản ngân hàng',
      path: PathName.BankAccountManagement,
      active: [PathName.BankAccountManagement].includes(pathname),
    },
    {
      key: 'menu-3',
      label: 'Quản lý DS1 Earn',
      path: PathName.DS1EarnManagement,
      active: [PathName.DS1EarnManagement].includes(pathname),
    },
    {
      key: 'menu-4',
      label: 'Quản lý lệnh nạp tiền',
      path: PathName.DepositManagement,
      active: [PathName.DepositManagement].includes(pathname),
    },
    {
      key: 'menu-5',
      label: 'Quản lý lệnh rút tiền',
      path: PathName.WithdrawManagement,
      active: [PathName.WithdrawManagement].includes(pathname),
    },
    {
      key: 'menu-6',
      label: 'Quản lý token phát hành',
      path: PathName.TokenManagement,
      active: [PathName.TokenManagement].includes(pathname),
    },
    {
      key: 'menu-7',
      label: 'Quản lý lệnh Mua-Bán người dùng',
      path: PathName.PurchaseSaleManagement,
      active: [PathName.PurchaseSaleManagement].includes(pathname),
    },
    {
      key: 'menu-8',
      label: 'Quản lý Mã giới thiệu',
      path: PathName.ReferralCodesManagement,
      active: [PathName.ReferralCodesManagement].includes(pathname),
    },
    {
      key: 'menu-9',
      label: 'Quản lý danh sách công ty',
      path: PathName.CompanyManagement,
      active: [PathName.CompanyManagement].includes(pathname),
    },
  ];

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-primary flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-white text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            DigiStock
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'bg-primary md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-8 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="text-white md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    DigiStock
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {menuItems.map(item => (
                <li className="items-center" key={item.key}>
                  <Link
                    className={`text-xs py-3 px-2 block text-white rounded-md ${
                      item.active ? 'bg-primary-light' : 'hover:text-blueGray-500'
                    }`}
                    to={item.path}
                  >
                    {/* <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (pathname?.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "} */}
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
