import OTPInput from 'components/OTPInput';
import React from 'react';

import Routes from 'routes/Routes';

function App() {
  return (
    <>
      <OTPInput />
      <Routes />
    </>
  );
}

export default App;
