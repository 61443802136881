import axios from 'axios';
import qs from 'qs';
import humps from 'humps';
import { API_URL } from 'constant';
import { toast } from 'components/Toast';
import { CookiesStorage } from './cookie-storage';
import { PathName } from 'constant';
import { SpecialErrorCodes } from './errors-code';
import { CookieKey } from 'constant';

export const request = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
});

request.interceptors.request.use(
  config => {
    config.params = humps.decamelizeKeys(config.params);
    config.data = humps.decamelizeKeys(config.data);

    const accessToken = CookiesStorage.getAccessToken(); // Get accessToken
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    return humps.camelizeKeys(response.data);
  },
  error => {
    const errorResponse = error?.response;
    console.log(errorResponse);
    if (errorResponse.status === 401) {
      CookiesStorage.clearCookieData(CookieKey.AccessToken);
      CookiesStorage.clearSession();
      toast('error', 'Token expired');
      window.location.href = PathName.AuthLogin;
      return Promise.reject(errorResponse?.data);
    }
    if (error.code === 'ECONNABORTED') {
      toast('error', 'Connection timeout');
      return Promise.reject(errorResponse?.data);
    }
    if (!SpecialErrorCodes.includes(errorResponse?.data.error)) {
      toast('error', errorResponse?.data?.error_detail?.message || errorResponse?.data?.error?.detail || 'Unknown error');
    }
    return Promise.reject(errorResponse?.data);
  }
);
