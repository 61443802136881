import React from 'react';
import isEqual from 'lodash/isEqual';

const getIconName = type => {
  switch (type) {
    case 'success':
      return 'fa-circle-check';
    case 'error':
      return 'fa-circle-xmark';
    default:
      return 'fa-circle-check';
  }
};

const getIconClassName = type => {
  switch (type) {
    case 'success':
      return 'text-green-400';
    case 'error':
      return 'text-red-400';
    default:
      return 'text-green-400';
  }
};

const Toast = ({ type, message }) => {
  const iconName = getIconName(type);
  const iconClassName = getIconClassName(type);
  return (
    <div className="flex items-center">
      <i className={`far ${iconName} ${iconClassName} mr-2`} />
      <span className="text-black w-100 toast-content-component">{message}</span>
    </div>
  );
};

export default React.memo(Toast, isEqual);
