import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// components
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import { CookiesStorage } from "utils/cookie-storage";
import { PathName } from "constant";

const Auth = () => {
  const isAuthenticated = CookiesStorage.isAuthenticated();
  return (
    <>
      {/* <Navbar transparent /> */}
      {isAuthenticated && (
        <Navigate to={PathName.UserManagement} replace />
      )}
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png") + ")",
            }}
          ></div>
          <Outlet />
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
};

export default Auth;
