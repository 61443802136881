import { CookieKey } from 'constant';
import { atom } from 'recoil';
import { CookiesStorage } from 'utils/cookie-storage';

export const userStateKey = 'user';
const userState = atom({
  key: userStateKey,
  default: CookiesStorage.getCookieData(CookieKey.UserInfo) || null,
});

export default userState;
