export const API_URL = process.env.REACT_APP_API_URL;

const CookieKeyPrefix = '@DS';
export const CookieKey = {
  AccessToken: `${CookieKeyPrefix}/accessToken`,
  UserInfo: `${CookieKeyPrefix}/userInfo`,
};

export const PathName = {
  AuthLogin: '/auth/login',
  AuthRegister: '/auth/register',
  Dashboard: '/admin/dashboard',
  UserManagement: '/admin/users',
  UserDetail: '/admin/users/:id',
  BankAccountManagement: '/admin/bank-accounts',
  BankAccountDetail: '/admin/bank-accounts/:id',
  DetailTokenOfUser: '/admin/bank-accounts/detail-token',
  HistoryInterestDetail: '/admin/bank-accounts/detail-token/history',
  TransactionManagement: '/admin/transactions',
  DepositManagement: '/admin/deposit',
  WithdrawManagement: '/admin/withdraw',
  CompanyManagement: '/admin/companies',
  CompanyDetail: '/admin/companies/:id',
  TokenManagement: '/admin/tokens',
  TokenManagementDetail: '/admin/tokens/detail',
  PurchaseSaleManagement: '/admin/purchase-sale',
  DS1EarnManagement: '/admin/ds1-earn',
  ReferralCodesManagement: '/admin/referral-codes',
  TokenDetail: '/admin/tokens/:tokenId',
};

export const TransactionTypes = {
  Deposit: 'deposit',
  Withdraw: 'withdraw',
};
export const TransactionStatus = {
  Pending: 'pending',
  Succeed: 'succeed',
  Cancled: 'cancled',
  Failed: 'failed',
};

export const UserStatus = {
  Active: 'ACTIVE',
  Unverified: 'UNVERIFIED',
  Blocked: 'BLOCK',
};

export const WithdrawStatus = {
  Pending: 'pending',
  Succeed: 'succeed',
  Failed: 'failed',
};

export const TransferMethod = [
  { value: 'chuyen_khoan_nhanh', label: 'Chuyển khoản nhanh' },
  { value: 'chuyen_khoan_thuong', label: 'Chuyển khoản thường' },
];

export const TokenManagementTabs = {
  totalSold: 'totalSold',
  totalTransaction: 'totalTransaction',
  walletsHolding: 'walletsHolding',
  transactionHistory: 'transactionHistory',
};

export const purchaseStatus = {
  matchedAPart: 'matchedAPart',
  allMatched: 'allMatched',
  waitingMatch: 'waitingMatch',
  rejected: 'rejected',
};

export const VN_PHONE_REGEX =
  /^(0)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/gm;
