import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import HeaderStats from 'components/Headers/HeaderStats.js';
import FooterAdmin from 'components/Footers/FooterAdmin.js';
import { CookiesStorage } from 'utils/cookie-storage';
import { PathName } from 'constant';

const Admin = () => {
  const isAuthenticated = CookiesStorage.isAuthenticated();
  return (
    <>
      {!isAuthenticated && <Navigate to={PathName.AuthLogin} replace />}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 min-h-screen">
        <AdminNavbar />
        {/* Header */}
        {/* <HeaderStats /> */}
        <div className="bg-secondary px-4 md:px-10 pt-16 md:pt-32 pb-4 md:pb-10 mx-auto w-full min-h-screen">
          <Outlet />
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
};

export default Admin;
